import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { getLocationQuestions } from "../merlinv1/beta_api";
import { likeQuestion } from "../merlinv1/beta_api";
import { dislikeQuestion } from "../merlinv1/beta_api";
import { searchLocationQuestions } from "../merlinv1/beta_api";

function CommunityPartial() {
    const [location, setLocation] = useState('');
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [ids, setIds] = useState([]) 
    const [likes, setLikes] = useState([])
    const [subjects, setSubjects] = useState([])
    const [subject, setSubject] = useState("");

    const getData = async () => {

      const res = await axios.get('https://geolocation-db.com/json/')
        
      setLocation(res.data.city)
      
      const res2 = await getLocationQuestions(location)
      console.log(res2)
      console.log (res2.data.answers !== undefined)
      if (res2.data.answers !== undefined) {
        setAnswers(res2.data.answers)
        setQuestions(res2.data.questions)
        setIds(res2.data.response_ids)
        setLikes(res2.data.likes)
        setSubjects(res2.data.subjects)

      
      for (let index = 0; index < res2.data.answers.length; index++){
        setCSS(index)
        setCSS(index + 'd')
        console.log('set')
        }
      }
    }

    const getSearchedData = async () => {
      const res = await searchLocationQuestions(location, subject)
      console.log(subject)
      console.log(res)
      console.log(res.PromiseResult)

      if ((res.data.answers.length) > 0){
        setAnswers(res.data.answers)
        setQuestions(res.data.questions)
        setIds(res.data.response_ids)
        setLikes(res.data.likes)
      }
    }

    const disableButton = async (id) => {
      document.getElementById(id).disabled = true;
    }

    const enableButton = async (id) => {
      document.getElementById(id).disabled = false;
    }

    const setCSS = async (id) => {
      document.getElementById(id).className = 'button-small btn align-center self-end text-center justify-center px-4 py-2 mr-8 font-bold rounded-3xl text-white text-md bg-blue-pondr hover:bg-blue-pondrdark hover:border-white hover:text-white focus:outline-none'
    }

    const changeCSS = async (id) => {
      document.getElementById(id).className = "button-small btn align-right self-end text-right justify-right px-4 py-2 mr-8 font-bold rounded-3xl text-white text-md bg-blue-pondrdark hover:bg-blue-pondrdark hover:border-white hover:text-white focus:outline-none"
    }

    useEffect( () => {
        //passing getData method to the lifecycle method
        getData()

    }, [])

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-25 md:pb-2">
          {/* Page header */}
          <div className="max-w-3xl pb-12 md:pb-5 text-center md:text-left">
            <h1 className="h1 mb-4">Welcome to the Anything Expert Community Page </h1>
            <p className="text-xl text-gray-600">
                See what your friend and classmates are asking!
            </p>
            <p className="text-md pb-1 text-gray-600">
                Getting Question from {location}...
            </p>
          </div>
          <div className="max-w-3xl pb-12 md:pb-10 text-center md:text-left">
            
          </div>

          {answers.length > 0 ? (
            <div
              style={{ height: "1000", width: "1000" }}
              className="h-full w-full overflow-auto"
            >
                  <ul className="flex text-md flex-grow flex-wrap items-center w-full">
                        <div className="h-full w-full pt-5 pb-5 mx-3 my-4 px-3 text-blue-pondr border-2 rounded-xl">
                        {answers.map((answer, index) => (
                            <div className="flex flex-col flex-wrap mx-3 my-4 mr-3 px-3 py-6 text-white bg-blue-pondr pr-3 rounded justify-center">
                              <p className="font-semibold"></p>
                              
                                  <div>
                                    <p
                                      key={index}
                                      className="mb-2 text-white w-max normal-case"
                                    >
                                      Question:
                                      {' ' + questions[index]}
                                      <hr className="my-auto mb-2 mt-5 w-0" />
                                      Answer:
                                      {answer}
                                      
                                    </p>
                                    <div className="text-right">
                                      <button
                                        id = {index}
                                        key = {index}
                                        onClick={async () => {
                                        const res = await likeQuestion(
                                          ids[index],
                                        );
                                        disableButton(index)
                                        changeCSS(index)
                                        console.log(document.getElementById(index).disabled)
                                        }}
                                      >
                                        👍 {likes[index]}
                                      </button>
                                      <button
                                        id = {index + 'd'}
                                        key = {index}
                                        onClick={async () => {
                                        const res = await dislikeQuestion(
                                          ids[index],
                                        );
                                        disableButton(index + 'd')
                                        changeCSS(index + 'd')
                                        console.log(document.getElementById(index + 'd').disabled)
                                        }}
                                      >
                                        👎
                                      </button>
                                    </div>
                                    <hr className="my-auto mb-2 mt-5 w-full" />
                                  </div>
                            </div>
                            ),
                            )}
                          </div>  
                  </ul>
            </div>
          ) : 
          <h4 className="text-center h3 mb-2 text-blue-pondr ml-5 mt-3">Good news... you can be the first to ask a question! </h4>
          }

          {/* Main content */}
          
        </div>
      </div>
    </section>
  );
}

export default CommunityPartial;
