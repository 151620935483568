import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";
import Transition from "../utils/Transition.js";
import Dropdown from "../utils/Dropdown";

const Header = (props) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);

  // close the mobile menu on click outsidemy
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const history = useHistory();

  // Declares a variable to store the className of the header links. Also checks to see which tab is selected
  const baseHeaderClassname =
    "text-black hover:text-blue-pondr mx-3 lg:mx-5 py-2 flex items-center transition duration-150 ease-in-out outline-none text-md";
  const currentSelectedPathname = window.location.pathname;

  return (
    <header
      className={` fixed w-full z-30 md:bg-opacity-80 text-md transition duration-300 ease-in-out ${
        !top && "text-md bg-white blur shadow-lg"
      }`}
    >
      <div className="text-md max-w-6xl font-medium mx-auto px-5 sm:px-6">
        <div className="text-md flex items-center justify-between h-16 md:h-20  outline-none">
          {/* Site branding */}
          {/* Logo */}
          <Link to="/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzP" className="block" aria-label="Cruip">
            <img
              className="mx-auto outline-none"
              src={
                "https://storage.googleapis.com/pondr-306720.appspot.com/general-site-images/q%20yellow.png"
              }
              width="50"
              height="20"
              alt="Hero"
            />
          </Link>

          {/* Desktop navigation */}
          <nav className="text-md hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex text-md flex-grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzP"
                  className={
                    currentSelectedPathname === "/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzP"
                      ? baseHeaderClassname +
                        " text-md font-semibold border-b-4 border-solid border-blue-pondr pb-2"
                      : baseHeaderClassname
                  }
                >
                  Quick Questions
                </Link>
              </li>
              <li>
                <Link
                  to="/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzLearn"
                  className={
                    currentSelectedPathname === "/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzLearn"
                      ? baseHeaderClassname +
                        " text-md font-semibold border-b-4 border-solid border-blue-pondr pb-2"
                      : baseHeaderClassname
                  }
                >
                  Learn
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYP"
                  className={
                    currentSelectedPathname === "/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYP"
                      ? baseHeaderClassname +
                        " text-md font-semibold border-b-4 border-solid border-blue-pondr pb-2"
                      : baseHeaderClassname
                  }
                >
                  Community
                </Link>
              </li> */}
            </ul>

            {/* Desktop sign in links */}
            
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">
            {/* Hamburger button */}
            <button
              className={`hamburger ${
                mobileNavOpen && "active"
              } focus:outline-none`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/* Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-2">
                  <li>
                    <Link
                      to="/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYP"
                      className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center"
                    >
                      Community
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (creds) => {
      dispatch(signOut(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
