import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../components/Input";
import Button from "../components/Button";
import ButtonFill from "../components/ButtonFill";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";
import { BiAtom } from "react-icons/bi";
import { BiBookOpen } from "react-icons/bi";
import { BsFillGearFill } from "react-icons/bs";
import { FaSquareRootAlt } from "react-icons/fa";
import { IoLanguage } from "react-icons/io5";
import { BiCodeCurly } from "react-icons/bi";
import {
  askAiQuestion,
  likeQuestion,
  getAiQuestions,
  logFirestoreEvent,
  dislikeQuestion,
} from "../../merlinv1/beta_api";
import { connect } from "react-redux";
import { logEvent, logScreenName } from "../../utils/CommonFunctions";
import ShowMoreText from "react-show-more-text";
import axios from 'axios'
import { askAnyQuestion } from "../../merlinv1/beta_api";

var subject = "Philosophy"

const QuestionFormSchema = Yup.object({
  question: Yup.string().required("Questions cannot be empty"),
});

const AIQuestionsEnglish = (props) => {
  const isDemo = props.demo;

  // State data for the screen
  const [answer, setAnswer] = useState();
  const [location, setLocation] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [textTyped, setTextTyped] = useState("");
  const [followup, setFollowup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseID, setResponseId] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questionNumbers, setQuestionNumbers] = useState([])
  const { productInfo } = props;
  const trigger = useRef(null);

  const disableButton = async (id) => {
    document.getElementById(id).disabled = true;
  }

  const enableButton = async (id) => {
    document.getElementById(id).disabled = false;
  }

  const setCSS = async (id) => {
    document.getElementById(id).className = 'button-small btn align-center self-end text-center justify-center px-4 py-2 mr-4 font-bold rounded-3xl text-white text-md bg-blue-pondrpurple hover:bg-blue-pondrpurpledark hover:border-white hover:text-white focus:outline-none'
  }

  const changeCSS = async (id) => {
    document.getElementById(id).className = "button-small btn align-right self-end text-right justify-right px-4 py-2 mr-4 font-bold rounded-3xl text-white text-md bg-blue-pondrpurpledark hover:bg-blue-pondrpurpledark hover:border-white hover:text-white focus:outline-none"
  }

  const setCorrectSubject = (index) => {
    console.log(index == 1)
    if (index == 0){
      subject = ('Philosophy')
    }
    else if (index == 1){
      subject = ('Economics')
    }
    else if (index == 2){
      subject = ('History')
    }
    else if (index == 3){
      subject = ('Law')
    }
    else if (index == 4){
      subject = ('Geography')
    }
    else if (index == 5){
      subject = ('Philosophy')
    }
  }
  const history = useHistory();

    //creating function to load ip address from the API
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      //console.log(res.data);
      setLocation(res.data.city);
    }

  // Fetches previous responses from redux/database
  useEffect(() => {
    // Helper for useEffect
    getData();
    const fetchData = async () => {
      try {
        if (!props.aiHistory[props.productId]) {
          const res = await getAiQuestions(props.productId);
          props.setHistory(res.data, props.productId);
        }
      } catch (err) {
        logFirestoreEvent("404Error", { err: err });
        history.push("/ErrorPage");
      }
      enableButton(0)
      enableButton(2)
    };

    if (!isDemo) {
      logScreenName("Q&A");
      logEvent("page_view", { page_name: "Q&A", productId: props.productId });
      fetchData();
    } else {
      logScreenName("DemoQ&A");
      logEvent("page_view", { page_name: "DemoQ&A" });
    }
  }, []);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(QuestionFormSchema),
    defaultValues: {
      question: "",
    }
  });

  const onSubmit = async (data) => {
    // Enables loading state
    setDisabled(true);
    setIsLoading(true);
    console.log(location);

    // ES6 Format of Async/Await/Catch
    try {
      let res = "";
      let responseData = "";
      let response_id = "";
      let new_answers = "";
      let new_questions = "";
      if (isDemo) {
        if (data.question === ("ok go on")){
          res = await askAnyQuestion(data.question, subject, questions, answers, location + ' go on');
        }
        if (data.question === ("please explain more")){
          res = await askAnyQuestion(data.question, subject, questions, answers, location + ' explain');
        }
        else {
          res = await askAnyQuestion(data.question, subject, questions, answers, location);
        }

        logFirestoreEvent("DemoAIQuestionAsked", { question: data.question });
        
        response_id = res.data['response_id']
        new_answers = res.data['AI Answer'].answers
        responseData = res.data["AI Answer"].answers[new_answers.length - 1]
        new_questions = res.data['AI Answer'].questions
        
      } 
      if (!responseData) {
        setIsLoading(false);
        alert(
          "Unexpected problem with AI, try refreshing or contacting tcstahur@purdue.edu"
        );
      } else {
        setAnswer({
          answers: responseData,
        });
        setResponseId(
          response_id
        )
        setQuestions(
          new_questions
        )
        setAnswers(
          new_answers
        )
      }
    } catch (err) {
      logFirestoreEvent("404Error", { err: err });
      history.push("/ErrorPage");
    }

    // Turns off loading state
    setIsLoading(false);
    setDisabled(false);
    setCSS(0)
    setCSS(2)
  };

  const trimAnswer = (answer) => {
    if (answer.indexOf(".") === -1) return answer;
    return answer.substring(0, answer.lastIndexOf(".") + 1);
  };

  return (
    <div className="">
      <meta name="viewport" content="width=device-width justify-center" />
      <h1 className="text-center h3 mb-2 text-blue-pondrpurple ml-5 mt-3">
        Anything Expert AI{" "}
      </h1>
      <p className="mr-0 font-bold text-gray-400 text-md text-center ml-50 pb-5">
          What subject are we talking about today?
        </p>
        <div className="justify-center pb-8" >
          <div
              className={
                "flex justify-evenly items-center w-full"
              }
            >
              <Link to='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzP'>
                <button>
                🧮
                </button>
              </Link>
              <Link to='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzE'>
                <button>
                📚
                </button>
              </Link>
              <Link to='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzC'>
                <button>
                💻
                </button>
              </Link>
              <Link to='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzS'>
                <button>
                🧪
                </button>
              </Link>
              <Link to='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzEN'>
                <button>
                ⚙️
                </button>
              </Link>
              <Link to='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzL'>
                <button>
                🌐
                </button>
              </Link>
            </div>
          </div>
        <p className="mr-0 font-bold text-gray-400 text-md text-left ml-50 pb-5">
          Humanities
        </p>
      <select
              className="normal-case rounded-xl bg-white shadow-lg p-2 -ml-2 border-none"
              onChange={(e) => {
                subject = (e.target.value);
                logEvent("CategorizedCategorySelected", {
                  category: e.target.value,
                });
              }}
          >
            <option value="Philosophy">
              Philosophy
            </option>
            <option value="Philosophy">
              Psychology
            </option>
            <option value="Law">
              Law
            </option>
            <option value="Economics">
              Economics
            </option>
            <option value="Geography">
              Geography
            </option>
            <option value="History">
              History
            </option>
            <option value="Language">
              Language
            </option>
            <option value="Poetry">
              Poetry
            </option>
            <option value="Music">
              Music
            </option>
        </select>
      <div className="pb-5"></div>
      <form
        className="mt-7 flex-1 w-full flex flex-wrap justify-center"
        data-aos="fade-up"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="md:flex-auto justify-center pb-1">
          <Input
            className="normal-case flex-1 w-full rounded-xl bg-white shadow-lg p-2 -ml-4 border-none"
            name="question"
            placeholder="&#128218;  Ask anything..."
            type="search"
            required
            ref={register}
            onChangeText={(newText) => setTextTyped(newText)}
          />
        </div>
        {isLoading ? (
          <div className="mt-5 mr-8 justify-left">
            <ReactLoading type="spin" color="#B0ADFF" height={30} width={30} />
          </div>
        ) : (
          <Button
            className="btn text-white bg-blue-pondrpurple hover:bg-blue-pondrpurpledark outline-none rounded-3xl"
            disabled={disabled || textTyped.split(" ").length < 3}
            type="submit"
          >
            Submit
          </Button>
        )}

        <div className="" />
      </form>
      {answer ? (
        <>
          <div className="normal-case mx-0 h-full w-full my-3 px-3 py-6 text-white bg-blue-pondrpurple rounded justify-center">
            <p className="font-semibold">Answer:</p>
            <div className="pb-5"></div>
              <div className={"flex flex-col"}>
                <p key={0} className="mb-2">
                  {(answer.answers)}
                </p>
                {followup.find(
                  (eachFollowup) => eachFollowup.index === 0
                ) !== undefined ? (
                  <p key={0} className="mb-2">
                    Explanation:&nbsp;
                    {(
                      followup.find(
                        (eachFollowup) => eachFollowup.index === 0
                      ).follow_up[0]
                    )}
                  </p>
                ) : (
                  <div />
                )}
                {isLoading ||
                !(
                  followup.length === 0 ||
                  followup.find(
                    (eachFollowup) => eachFollowup.index === 0
                  ) === undefined
                ) ? (
                  <div />
                ) : (
                  <div className="text-right mr-2">
                    <button
                      onClick={async () => {
                        onSubmit({ question: "ok go on" });
                      }}
                      className="button-small btn align-center self-end text-center justify-center px-4 py-2 mr-4 font-bold rounded-3xl text-white text-md bg-blue-pondrpurple hover:bg-blue-pondrpurpledark hover:border-white hover:text-white focus:outline-none"
                    >
                      👉
                    </button>
                    <button
                      onClick={async () => {
                        onSubmit({ question: "please explain more" });
                      }}
                      className="button-small btn align-center self-end text-center justify-center px-4 py-2 mr-4 font-bold rounded-3xl text-white text-md bg-blue-pondrpurple hover:bg-blue-pondrpurpledark  hover:border-white hover:text-white focus:outline-none"
                    >
                      ❔
                    </button>
                    <button
                      id = {0}
                      onClick={async () => {
                      const res = await likeQuestion(
                        responseID,
                      );
                      disableButton(2)
                      changeCSS(2)
                      disableButton(0)
                      changeCSS(0)
                      console.log(document.getElementById(0).disabled)
                      }}
                    >
                      👍
                    </button>
                    <button
                      id = {2}
                      key = {2}
                      onClick={async () => {
                      const res = await dislikeQuestion(
                        responseID,
                      );
                      disableButton(2)
                      changeCSS(2)
                      disableButton(0)
                      changeCSS(0)
                      console.log(document.getElementById(2).disabled)
                      }}
                    >
                      👎
                    </button>
                  </div>
                )}
              </div>
          </div>
        </>
      ) : null}
      <div className="pb-5"></div>
      {!isDemo ? (
        <div />
      ) : (
        <div>
          <p className="text-xl mt-2 mb-2 text-gray-400 pb-5 font-bold text-center">
            Previously Asked Questions
          </p>

          {answers.length > 0 ? (
            <div
              style={{ height: "1000", width: "1000" }}
              className="h-full w-full overflow-auto justify-center"
            >
                  <ul className="flex text-md flex-grow flex-wrap items-center w-full">
                        <div className="h-full w-full pt-5 pb-5 mx-3 my-4 px-3 text-blue-pondrpurple border-2 rounded-xl">
  
                            <div className="flex flex-col flex-wrap mx-3 my-4 mr-3 px-3 py-6 text-white bg-blue-pondrpurple pr-3 rounded justify-center">
                              <p className="font-semibold"></p>
                              {answers.map(
                                (answer, index) => (
                                  <p
                                    key={index}
                                    className="mb-2 text-white w-max normal-case"
                                  >
                                    Question:
                                    {' ' + questions[index]}
                                    <hr className="my-auto mb-2 mt-5 w-0" />
                                    Answer:
                                    {answer}
                                    <hr className="my-auto mb-2 mt-5 w-full" />
                                  </p>
                                )
                              )}
                            </div>
                          </div>  
                  </ul>
            </div>
          ) : null}
        </div>
      )}
      <p
        className={
          "mr-4 font-bold text-gray-400 text-md ml-50 mb-3 -mt-5 pt-5 pb-5"
        }
      >
        {" "}
        If repeating answer, refresh page. If your having trouble? Try asking...
      </p>
      <div className="grid grid-cols-3 w-full grid-flow-row -ml-10 gap-4">
        {[
          "Who was Plato?",
          "Whats an economy?",
          "What historical events happened in the 1500s?",
          "What Is Common Law?",
          "Which is the largest waterfall in the world?",
          "what is something cool about the humanities!"
        ].map((eachAutoQuestion, index) => (
          <ButtonFill
            className="btn text-black -p-3 w-full rounded-4xl bg-white border-gray-400 hover:bg-blue-pondrpurple hover:border-blue-pondrpurple border-2 focus:outline-none mx-3 w-max mb-4 ml-10 text-blue-pondrpurple leading-5 text-sm border-none rounded-full"
            type="button"
            key={index}
            onClick={() => {
              setCorrectSubject(index);
              console.log(subject)
              setValue("question", eachAutoQuestion);
              setTextTyped(eachAutoQuestion);
              onSubmit({ question: eachAutoQuestion });
            }}
          >
            {eachAutoQuestion}
          </ButtonFill>
        ))}
      </div>
      <p
        className={
          "mr-4 font-bold text-gray-400 text-md ml-50 mb-3 -mt-5 pt-5 pb-5"
        }
      >
        {" "}
        Tips to get the most out of this AI:
      </p>
      <p
        className={
          "mr-4 font-bold text-gray-400 text-md ml-50 mb-3 -mt-5 pt-5 pb-5"
        }
      >
        {" "}
        1) The AI understands context so feel free to ask for clarification or converse with it if you don't get an answer.
      </p>
      <p
        className={
          "mr-4 font-bold text-gray-400 text-md ml-50 mb-3 -mt-5 pt-5 pb-5"
        }
      >
        {" "}
        2) It doesn't understand pure math yet so refrain from asking questions like what the integral of x^(6/3) for example.
      </p>
      <p
        className={
          "mr-4 font-bold text-gray-400 text-md ml-50 mb-3 -mt-5 pt-5 pb-5"
        }
      >
        {" "}
        3) Don't forget to like good answers!! It really helps make the AI better for everyone.
      </p>
      <p
        className={
          "mr-4 font-bold text-gray-400 text-md ml-50 mb-3 -mt-5 pt-5 pb-5"
        }
      >
        {" "}
        Happy Studying!
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    aiHistory: state.app.aiHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHistory: (newHistory, productId) =>
      dispatch({
        type: "SET_AI_HISTORY",
        newHistory: newHistory,
        productId: productId,
      }),
    addQuestion: (newQuestion, productId) =>
      dispatch({
        type: "ADD_AI_QUESTION",
        newQuestion: newQuestion,
        productId: productId,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AIQuestionsEnglish);
