import React from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { logFirestoreEvent } from "../merlinv1/beta_api";

const Footer = () => {
  return (
    <footer>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 mt-10">
        {/* </div> */}

        {/* Bottom area */}
        <div className="flex flex-col sm:flex-row items-center justify-between py-4 md:py-8 border-gray-200">
          {/* Copyrights note */}

          <div
            className={
              "flex flex-row items-center justify-between w-full"
            }
          >
            <Link
              to="/privacy-policy"
              className="text-sm text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out outline-none"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms"
              className="text-sm text-gray-600 pl-30 hover:text-gray-900 hover:underline transition duration-150 ease-in-out outline-none"
            >
              Terms and Conditions
            </Link>
            <div
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              Contact me at tcstahur@purdue.edu
            </div>
          </div>
        </div>
        <div className="text-xs text-gray-600 mb-4 text-center">
          {" "}
          &copy; 2021 Copyright Quasi Ventures. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
