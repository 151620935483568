import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Input from "../pages/components/Input";
import Button from "../pages/components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactLoading from "react-loading";
import * as Yup from "yup";

const Quizing = (props) => {

    const [textTyped, setTextTyped] = useState("");
    //const [isLoading, setIsLoading] = useState(false);
    //const [disabled, setDisabled] = useState(false);

    const QuestionFormSchema = Yup.object({
        question: Yup.string().required("Questions cannot be empty"),
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        resolver: yupResolver(QuestionFormSchema),
        defaultValues: {
        question: "",
        }
    });

    return (
        <section>
            <div className="px-20 md:pt-40">
                <div className="max-w-3xl mx-auto lg:max-w-none">
                    <div className="">
                        <div className="col-span-1 self-center">
                        <form
                                className="mt-7 flex-1 w-full flex flex-wrap justify-center"
                                data-aos="fade-up"
                                //onSubmit={handleSubmit(onSubmit)}
                            >
                            <div className="md:flex-auto justify-center pb-1">
                                <p className="text-left font-bold text-gray-400">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </div>

                        </form>
                        </div>
                        <div className="col-span-1">
                            <Input
                                    className="normal-case flex-1 w-full rounded-xl bg-white shadow-lg p-2 -ml-4 border-none"
                                    name="question"
                                    placeholder="Enter ansers a csv (Ex. a, b, c, d)"
                                    type="search"
                                    required
                                    ref={register}
                                    onChangeText={(newText) => setTextTyped(newText)}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex justify-end pt-10'>
                    <Button
                        className="btn text-white bg-blue-pondr hover:bg-blue-pondrdark outline-none rounded-3xl"
                        //disabled={disabled || textTyped.split(" ").length < 3}
                        type="submit"
                    >
                        Submit Questions
                    </Button>
                </div>
            </div>
        </section>
    );
}
export default Quizing;
