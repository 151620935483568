import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Input from "../pages/components/Input";
import Button from "../pages/components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";
import { getAICurriculum } from "../merlinv1/beta_api";
import ReactLoading from "react-loading";
import * as Yup from "yup";

const Learning = (props) => {

    var subject = '';
    var knowledgeBase = '';
    var goal = '';

    const [textTypedKnowledge, setTextTypedKnowledge] = useState("");
    const [textTypedGoal, setTextTypedGoal] = useState("");
    //const [isLoading, setIsLoading] = useState(false);
    //const [disabled, setDisabled] = useState(false);

    const history = useHistory();

    const QuestionFormSchema = Yup.object({
        question: Yup.string().required("Questions cannot be empty"),
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        resolver: yupResolver(QuestionFormSchema),
        defaultValues: {
        question: "",
        }
    });

    const onSubmit = async (data) => {
        try {
            let res = ''
            res = await getAICurriculum(subject, textTypedKnowledge, textTypedGoal)
            history.push("/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzLecture");
        } catch (err) {
            history.push("/ErrorPage");
          }
    }

    return (
        <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="max-w-3xl mx-auto lg:max-w-none">
                <article>
                {/* Article header */}
                <header className="max-w-3xl mx-auto mb-20">
                    {/* Title */}
                    <h1 className="h1 text-center mb-4">
                        If you want to learn, your in the right place!
                    </h1>
                    <p className="text-center font-bold text-gray-400">
                        but first I have to learn a little bit about you...
                    </p>
                </header>

                {/* Article content */}
                <div className="lg:flex pb-16" data-sticky-container>
                <p className="font-bold text-gray-400">
                    What subject do you want to learn?
                </p>
                <div className="pl-12">
                    <select
                        className="normal-case rounded-xl bg-white shadow-lg p-2 -ml-2 border-none"
                        onChange={(e) => {
                            subject = (e.target.value);
                            console.log(subject);
                        }}
                        >
                            <option value="Statistics">
                            Statistics
                            </option>
                            <option value="Calculus">
                            Calculus
                            </option>
                            <option value="Algebra">
                            Algebra
                            </option>
                            <option value="Cryptography">
                            Cryptography
                            </option>
                            <option value="Probability">
                            Probability
                            </option>
                            <option value="Linear Algebra">
                            Linear Algebra
                            </option>
                        </select>
                    </div>
                </div>

                <div className="" data-sticky-container>
                <p className="font-bold text-gray-400">
                    What is your current knowledge base?
                </p>
                    <div className="">
                        <form
                                className="mt-7 flex-1 w-full flex flex-wrap justify-center"
                                data-aos="fade-up"
                                //onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="md:flex-auto justify-center pb-1">
                                <Input
                                    className="normal-case flex-1 w-full rounded-xl bg-white shadow-lg p-2 -ml-4 border-none"
                                    name="question"
                                    placeholder="..."
                                    type="search"
                                    required
                                    ref={register}
                                    onChangeText={(newText) => setTextTypedKnowledge(newText)}
                                />
                                </div>

                        </form>
                    </div>
                </div>

                <div className="" data-sticky-container>
                    <p className="font-bold text-gray-400">
                        What is your goal?
                    </p>
                    <div className="">
                        <form
                                className="mt-7 flex-1 w-full flex flex-wrap justify-center"
                                data-aos="fade-up"
                                //onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="md:flex-auto justify-center pb-1">
                                <Input
                                    className="normal-case flex-1 w-full rounded-xl bg-white shadow-lg p-2 -ml-4 border-none"
                                    name="question"
                                    placeholder="..."
                                    type="search"
                                    required
                                    ref={register}
                                    onChangeText={(newText) => setTextTypedGoal(newText)}
                                />
                                </div>

                            </form>
                        </div>
                </div>
                
                <div className='flex place-content-center'>
                    <Button
                        onClick={async () => {
                            onSubmit();
                          }}
                        className="btn text-white bg-blue-pondr hover:bg-blue-pondrdark outline-none rounded-3xl"
                        //disabled={disabled || textTyped.split(" ").length < 3}
                        type="submit"
                    >
                        Generate Curriculum
                    </Button>
                </div>

                {/* Article footer */}
                </article>
            </div>
            </div>
        </div>
        </section>
    );
}

export default Learning;
