import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./css/style.scss";
import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
// Landing pages:
import ContactUs from "./pages/ContactUs";
import Community from "./pages/Community";
import Purdue from "./pages/Colleges/Purdue";
import Learn from "./pages/Learn";
import Lecture from "./pages/Lecture";
import Quiz from "./pages/Quiz";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import PageNotFound from "./pages/PageNotFound";

// User signed in components:
import ProtectedRoute from "./partials/protectedRoute";
import UnprotectedRoute from "./partials/UnprotectedRoute";
import Wrapper from "./utils/Wrapper";
import MathQuestions from "./pages/Subjects/MathQuestions";
import EnglishQuestions from "./pages/Subjects/EnglishQuestions";
import CompsiQuestions from "./pages/Subjects/CompsiQuestions";
import ScienceQuestions from "./pages/Subjects/ScienceQuestions";
import EnginieeringQuestions from "./pages/Subjects/EngineeringQuestions";
import AIQuestionsLanguage from "./pages/AIQuestions/AIQuestionsLanguage";

// Required StyleSheets
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import firebase from "firebase";
import "firebase/auth";
import UnderConstruction from "./pages/UnderConstruction";
import LanguageQuestions from "./pages/Subjects/LanguageQuestions";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, []);

  //if (window.location.host.split(".")[0] == "college") {
  //   It checks if the subdomain is named college.
  //      return (
  //        <Switch>
  //          <Route exact path="/purdue">
  //            <Purdue />
  //          </Route>

  //          <Route exact path="/*">
  //            <PageNotFound />
  //          </Route>
  //        </Switch>
  //      );
  //}

  return (
    <Switch>
      <Route exact path='/' render={() => <Redirect to={"/home"} />} />

      <Route exact path='/home'>
        <MathQuestions />
      </Route>
      <Route exact path='/purdue'>
        <Purdue />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzLearn'>
        <Learn />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzLecture'>
        <Lecture />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzQuiz'>
        <Quiz />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzP'>
        <MathQuestions />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzE'>
        <EnglishQuestions />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzC'>
        <CompsiQuestions />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzS'>
        <ScienceQuestions />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzEN'>
        <EnginieeringQuestions />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYzL'>
        <LanguageQuestions />
      </Route>
      <Route exact path='/SXnNPbF6V0BzN76ptlvYA06SzcE3RWR3EYP'>
        <Community />
      </Route>
      <Route exact path='/privacy-policy'>
        <PrivacyPolicy />
      </Route>
      <Route exact path='/terms'>
        <TermsAndConditions />
      </Route>
      <Route exact path='/*'>
        <PageNotFound />
      </Route>

      {/* Protected routes are enables only when user signs in */}
    </Switch>
  );
};

export default App;
