import React, { useEffect, useState } from "react";
import Wrapper from "../../utils/Wrapper";
import AIQuestionsCompsi from "../AIQuestions/AIQuestionsCompsi";
import { logFirestoreEvent } from "../../merlinv1/beta_api";
import Header from "../../partials/Header";

const CompsiQuestions = () => {
  // State variables for the current tab selected
  const [tabSelected, setTabSelected] = useState(1);

  useEffect(() => {
    logFirestoreEvent("DemoView", {});
  }, []);

  // Replace 3rd index with "<CategoriesReviews key={2} demo={true} />," if you want to show Categorized in demo
  const tabs = [
    "My Products/Home",
    <div>
      <div className="pb-10"></div>
      <AIQuestionsCompsi key={1} demo={true} /> 
    </div>,
  ];

  return (
        <div className={"flex justify-between h-full"}>
        <Header />
      <Wrapper isGrey={true} dontShowHeader={true}>
        <div className="w-4/5 mx-auto">{tabs[tabSelected]}</div>
      </Wrapper>
    </div>
  );
};

export default CompsiQuestions;
